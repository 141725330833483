<template>
  <div>
    <custom-toolbar
      :total="$store.getters['role/total']"
      :title="$t('Roles')"
      :add="'Nouveau rôle'"
      @refresh="getList"
      @add="openForm"
    />
    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="$store.state.role.field.label"
              label="Filtrer par libellé"
              outlined
              dense
              placeholder="Filtrer par libellé"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="$store.state.role.field.code"
              label="Filtrer par code"
              outlined
              dense
              placeholder="Filtrer par code"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['role/roles']"
        :items-per-page="$store.getters['role/per_page']"
        :page.sync="$store.getters['role/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <template #[`item.deleted_at`]="{item}">
          <v-chip
            v-show="item.deleted_at !== null"
            color="error"
            small
          >
            <span class="font-weight-bold">{{ item.deleted_at ? formattingDate(`${item.deleted_at}`) : null }}</span>
          </v-chip>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            @edit="openForm(item)"
            @remove="openItem(item)"
            :view="false"
            :lock="false"
            :status="false"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.recovery.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getListPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getList"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--    <reduct-code-form
      :dialog="dialog"
      :object="item"
      @clickout="closeDialog"
    />-->
    <role-form
      :dialog="dialog"
      :object="item"
      @clickout="closeDialog"
    >
    </role-form>
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
  </div>
</template>

<script>
import {
  getCurrentInstance, onMounted, ref, watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { formatDate, formatDateWithoutHour } from '@core/date/dateFunctions'
import {
  mdiAccountLock,
  mdiDeleteOutline, mdiDeleteRestore,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import RoleForm from '@core/components/role/RoleForm'
import store from '@/store'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";

export default {
  name: 'Role',
  components: {ButtonsGroup, CustomToolbar, RoleForm, ConfirmDialog },
  setup() {
    const instance = getCurrentInstance()
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const item = ref({})
    const date = ref(null)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const menu = ref(false)
    const messageDialog = ref('')
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const dialogDelete = ref(false)
    const isAlertVisible = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const { router } = useRouter()

    const save = d => {
      menuref.value.save(d)
    }

    const getList = (page = 1) => {
      isDialogVisible.value = true
      store.state.role.field.to_store = false
      store.state.role.field.paginate = 10
      store.dispatch('role/getRoleList', { page, field: store.state.role.field }).then(() => {
        currentPage.value = store.getters['role/current_page']
        lastPage.value = store.getters['role/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false

        // eslint-disable-next-line no-shadow
      }).catch(errorResponse => {
        message.value = errorResponse.response.data.message
        error.value = errorResponse.response.data.message
        isAlertVisible.value = true
      })
    }

    const refreshList = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('role/refreshRoleList', { page, field: store.state.role.field }).then(() => {
        currentPage.value = store.getters['role/current_page']
        lastPage.value = store.getters['role/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const filterItems = () => {
      setTimeout(() => {
        getList()
      }, 1500)
    }

    const getListPerPage = per_page => {
      isDialogVisible.value = true
      store.state.role.field.paginate = per_page
      store.state.role.field.to_store = false
      store.dispatch('role/getRoleList', { field: store.state.role.field }).then(() => {
        currentPage.value = store.getters['role/current_page']
        lastPage.value = store.getters['role/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const deleteItem = () => {
      isDialogVisible.value = true
      if (item.value.deleted_at === null) {
        store.dispatch('role/deleteRole', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          isDialogVisible.value = false
          notify(false, true, err.response.data.message)
        })
      } else {
        store.dispatch('role/restoreRole', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          isDialogVisible.value = false
          notify(false, true, err.response.data.message)
        })
      }
    }

    const openForm = val => {
      if (val) {
        item.value = {
          id: val.id,
          label: val.label,
          code: val.code,
          privileges: val.privileges,
          actions: [],
        }
      } else {
        item.value = {
          id: null,
          label: null,
          code: null,
          actions: [],
          privileges: []
        }
      }
      dialog.value = true
    }

    const formattingDate = date => formatDate(date)

    const formattingDateExHour = date => formatDateWithoutHour(date)

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const deleteItemConfirm = () => {
      this.closeDelete()
    }

    const openItem = object => {
      messageDialog.value = object.deleted_at === null ? `Voulez-vous supprimer le role ${object.label}?` : `Voulez-vous restaurer le role ${object.label}?`
      item.value = object
      dialogDelete.value = true
    }

    onMounted(() => {
      store.state.role.field.user_id = null
      getList(store.getters['role/current_page'])
    })

    const openDetails = item => {
      // router.push({ name: 'showreductcode', params: { id: item.id } })
    }

    watch(() => store.state.user.lang, (lang, newlang) => {
      if (lang !== newlang) {
        store.state.role.field.to_store = false
        refreshList(store.getters['role/current_page'])
      }
    })

    return {
      openDetails,
      deleteItem,
      dialog,
      item,
      messageDialog,
      formattingDateExHour,
      loading,
      closeDialog,
      openForm,
      filterItems,
      openItem,
      getListPerPage,
      deleteItemConfirm,
      closeDelete,
      save,
      getList,
      formattingDate,
      currentPage,
      message,
      lastPage,
      isAlertVisible,
      isDialogVisible,
      dialogDelete,
      error,
      date,
      menu,
      picker,
      menuref,
      headers: [
        { text: 'LIBELLE', value: 'label' },
        { text: 'CODE', value: 'code' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiDeleteRestore,
        mdiPlus,
      },
    }
  },
}
</script>

<style scoped>

</style>
