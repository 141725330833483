<template>
  <v-menu
    ref="menuref"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="myDate"
        :label="label"
        v-bind="attrs"
        clearable
        v-on="on"
        hide-details
        outlined
        dense single-line
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="myDate"
      :locale="$store.state.user.lang"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      color="primary"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import {
  computed, ref, onMounted, watch, getCurrentInstance,
} from '@vue/composition-api'

export default {
  name: 'DatePickerCustom',

  model: {
    prop: 'date',
    event: 'update:is-form-active',
  },

  emits: ['datechange', 'update:date'],

  props: {
    date: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)

    const myDate = computed({
      get: () => props.date,
      set: value => emit('update:date', value),
    })

    const save = d => {
      menuref.value.save(d)

      emit('update:date', d)
    }

    const clearEvent = event => {
      emit('update:date', event)
    }

    return {
      clearEvent,
      myDate,
      save,
      menu,
      picker,
      menuref,
    }
  },
}
</script>

<style scoped>

</style>
