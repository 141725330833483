<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="750px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">{{ item.id !== null ? "Modifier le rôle" : "Nouveau rôle" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.label"
                    type="text"
                    label="Libellé"
                    :rules="[rules.label]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.code"
                    label="Code"
                    type="text"
                    :rules="[rules.code]"
                    :readonly="item.id !== null"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <!--                  <v-divider v-if="!allSelected"></v-divider>-->
                  <v-text-field
                    v-model="search"
                    type="text"
                    label="Rechercher un privilège"
                    :loading="isActionsLoading"
                    :rules="[rules.actions]"
                  ></v-text-field>
                  <v-list>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"

                      >
                        <div
                          v-scroll.self="onScroll"
                          style="max-height: 170px"
                          class="overflow-y-auto"

                        >
                          <template
                            v-for="item in categories"

                          >
                            <v-list-item
                              v-if="!selected.includes(item)"
                              :key="item.text"
                              :disabled="loading"
                              @click="selected.push(item)"
                              v-show="!isActionsLoading"
                            >
                              <template v-slot:prepend>
                                <v-icon
                                  :disabled="loading"
                                  :icon="item.icon"
                                ></v-icon>
                              </template>

                              <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item>
                          </template>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-row
                          v-scroll.self="onScrollSelects"
                          align="center"
                          justify="start"
                          style="max-height: 165px"
                          class="overflow-y-auto mt-1"
                        >
                          <v-col
                            v-for="(selection, i) in selections"
                            :key="selection.text"
                            cols="auto"
                            class="py-1 pe-0"
                          >
                            <v-chip
                              :disabled="loading"
                              close
                              @click:close="selected.splice(i, 1)"
                            >
                              <v-icon
                                :icon="selection.icon"
                                start
                              ></v-icon>

                              {{ selection.text }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ item.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, ref, watch,
  onMounted,
} from '@vue/composition-api'
import {
  mdiCalendar, mdiEyeOffOutline, mdiEyeOutline, mdiMagnify,
} from '@mdi/js'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import { formatDate } from '@core/date/dateFunctions'
import DatePickerCustom from '@core/components/datepicker/DatePickerCustom'
import store from '@/store'

export default {
  name: 'RoleForm',

  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['clickout', 'submitted'],

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)

    const menu = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const isActionsLoading = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const loading = ref(false)
    const search = ref('')
    const searchField = ref('')
    const selected = ref([])
    const responses = ref([
      { text: 'OUI', value: true },
      { text: 'NON', value: false },
    ])
    const rules = {
      label: true,
      code: true,
      actions: true,
    }

    const scrollActions = ref(0)
    const scrollSelect = ref(0)

    const items = computed({
      get: () => {
        const result = []
        store.getters['action/actions'].forEach(action => {
          if (item.value.privileges && item.value.privileges.findIndex(t => t.action_id === action.id) < 0) { result.push({ text: action.label, code: action.code, id: action.id }) }
        })

        return result
      },
      set: value => emit('update:object', value),
    })

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const instance = getCurrentInstance()

    const save = d => {
      menuref.value.save(d)
    }

    const submitForm = () => {
      isAlertVisible.value = false
      selected.value.forEach(select => {
        item.value.actions.push({ id: select.id })
      })
      isDialogVisible.value = true
      if (item.value.id !== null) {
        store.dispatch('role/updateRole', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true
            rules.init_quantity = error.response.data.errors.init_quantity !== undefined ? error.response.data.errors.init_quantity[0] : true
            rules.quantity_per_user = error.response.data.errors.quantity_per_user !== undefined ? error.response.data.errors.quantity_per_user[0] : true
            rules.is_percent = error.response.data.errors.is_percent !== undefined ? error.response.data.errors.is_percent[0] : true
            rules.end_date = error.response.data.errors.end_date !== undefined ? error.response.data.errors.end_date[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('role/addRole', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true
            rules.init_quantity = error.response.data.errors.init_quantity !== undefined ? error.response.data.errors.init_quantity[0] : true
            rules.quantity_per_user = error.response.data.errors.quantity_per_user !== undefined ? error.response.data.errors.quantity_per_user[0] : true
            rules.is_percent = error.response.data.errors.is_percent !== undefined ? error.response.data.errors.is_percent[0] : true
            rules.end_date = error.response.data.errors.end_date !== undefined ? error.response.data.errors.end_date[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }

    const next = () => {
      loading.value = true

      setTimeout(() => {
        search.value = ''
        selected.value = []
        loading.value = false
      }, 2000)
    }

    const allSelected = computed({
      get: () => selected.value.length === items.value.length,
      set: value => emit('update:object', value),
    })

    const categories = computed({
      get: () => {
        const search2 = search.value.toLowerCase()

        if (!search2) return items.value

        return items.value.filter(item => {
          const text = item.text.toLowerCase()

          return text.indexOf(search2) > -1
        })
      },
      set: value => emit('update:object', value),
    })

    const selections = computed({
      get: () => {
        const selections = []
        for (const selection of selected.value) {
          selections.push(selection)
        }

        return selections
      },
      set: value => emit('update:object', value),
    })

    watch(() => props.object.id, (value, oldValue) => {
      getActions()
      if (item.value.privileges && item.value.privileges.length > 0) {
        item.value.privileges.forEach(privilege => {
          selected.value.push({ id: privilege.action_id, code: privilege.action.code, text: privilege.action.label })
          items.value.splice(items.value.findIndex(t => t.id === privilege.action_id), 1)
        })
      }
    }, { deep: true })

    const searchFocus = () => {
      search.value = ''
    }

    watch(() => selected.value, () => {
      search.value = ''
    })

    const onScroll = () => {
      scrollActions.value++
    }

    const onScrollSelects = () => {
      scrollSelect.value++
    }

    const getActions = () => {
      isActionsLoading.value = true
      store.dispatch('action/refreshActionList', { page: 1, field: store.state.action.field }).then(() => {
        isActionsLoading.value = false
      }).catch(error => {
        console.log(error)
        isActionsLoading.value = false
      })
    }

    onMounted(() => {
      getActions()
    })

    watch(() => store.state.user.lang, () => {
      getActions()
    })

    const close = () => {
      rules.label = true
      rules.code = true
      rules.actions = true
      isAlertVisible.value = false
      selections.value.length = 0
      selected.value.length = 0
      instance.emit('clickout')
    }

    return {
      isActionsLoading,
      searchField,
      onScroll,
      onScrollSelects,
      scrollActions,
      scrollSelect,
      searchFocus,
      selections,
      categories,
      allSelected,
      items,
      search,
      selected,
      next,
      close,
      loading,
      responses,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      menu,
      picker,
      menuref,
      item,
      color,
      save,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline, mdiMagnify,
      },
    }
  },
}
</script>

<style scoped>
.row {
  margin-bottom: -30px
}
.v-card__actions:last-child {
  margin-top: 35px;
}

@media screen and (max-width: 667px){
  .v-card__actions:last-child {
    margin-top: 40px;
  }
  .row {
    margin-bottom: -30px
  }
}
</style>
